import React from 'react'
import { Card, Text } from 'theme-ui'
import Section from '@components/Section'

const Commitment = props => (
  <Section aside title='My Commitment' {...props}>
    <Card variant='paper'>
      <Text variant='p'>
        My commitment is to cover a maximum number of major coding/technical
        problems so that you could do your programming without any hurdle.
      </Text>
    </Card>
  </Section>
)

export default Commitment
